import {
  CREATE_MEMBER_REQUEST,
  CREATE_MEMBER_REQUEST_FAILURE,
  CREATE_MEMBER_REQUEST_SUCCESS,
  DELETE_MEMBER_REQUEST,
  DELETE_MEMBER_REQUEST_FAILURE,
  DELETE_MEMBER_REQUEST_SUCCESS,
  EDIT_ROLE_TO_MEMBER_REQUEST,
  EDIT_ROLE_TO_MEMBER_REQUEST_FAILURE,
  EDIT_ROLE_TO_MEMBER_REQUEST_SUCCESS,
  GET_ALL_CLINIC_REQUEST,
  GET_ALL_CLINIC_REQUEST_FAILURE,
  GET_ALL_CLINIC_REQUEST_SUCCESS,
  GET_MEMBER_CACHE_DATA_CLEAR_REQUEST,
  GET_MEMBER_REQUEST,
  GET_MEMBER_REQUEST_FAILURE,
  GET_MEMBER_REQUEST_SUCCESS,
  UPDATE_MEMBER_REQUEST,
  UPDATE_MEMBER_REQUEST_FAILURE,
  UPDATE_MEMBER_REQUEST_SUCCESS,
  UPDATE_MEMBER_STATUS_LOCAL,
  UPDATE_MEMBER_STATUS_REQUEST,
  UPDATE_MEMBER_STATUS_REQUEST_FAILURE,
  UPDATE_MEMBER_STATUS_REQUEST_SUCCESS,
  VIEW_MEMBER_CACHE_DATA_CLEAR_REQUEST,
  VIEW_MEMBER_REQUEST,
  VIEW_MEMBER_REQUEST_FAILURE,
  VIEW_MEMBER_REQUEST_SUCCESS,
} from '../../actions/actionTypes'

import { getConcatenatedData, removedDuplicatesData } from '../../../utils/helper'
import { createMemberActions, MemberInterface } from '../../schema/member/memberSchema'

const initialState: MemberInterface = {
  memberCreateLoader: false,
  memberCreateData: [],
  memberCreateError: null,
  getMemberLoader: false,
  getMemberData: null,
  getMemberMeta: null,
  getMemberError: null,
  viewMemberLoader: false,
  viewMemberData: null,
  viewMemberError: null,
  updateMemberLoader: false,
  updateMemberData: null,
  updateMemberError: null,
  deleteMemberLoader: false,
  deleteMemberData: null,
  deleteMemberError: null,
  memberStatusUpdateLoader: false,
  memberStatusUpdateData: null,
  memberStatusUpdateError: null,
  allClinicData: null,
  allClinicLoader: false,
  allClinicError: null,
  editRoleToMemberData: null,
  editRoleToMemberLoader: false,
  editRoleToMemberError: null,
}

const memberReducer = (state = initialState, action: createMemberActions) => {
  switch (action.type) {
    case CREATE_MEMBER_REQUEST:
      return {
        ...state,
        memberCreateLoader: true,
      }
    case CREATE_MEMBER_REQUEST_SUCCESS:
      return {
        ...state,
        memberCreateLoader: false,
        memberCreateData: action.payload,
        memberCreateError: null,
      }
    case CREATE_MEMBER_REQUEST_FAILURE:
      return {
        ...state,
        memberCreateLoader: false,
        memberCreateData: null,
        memberCreateError: action.payload.memberCreateError,
      }
    case GET_MEMBER_REQUEST:
      return {
        ...state,
        getMemberLoader: true,
      }
    case GET_MEMBER_REQUEST_SUCCESS:
      return {
        ...state,
        getMemberLoader: false,
        getMemberData: removedDuplicatesData(state.getMemberData, action?.payload?.getMemberData, 'id'),
        getMemberDataForClinic: getConcatenatedData(state.getMemberData, action?.payload?.getMemberData, 'id'),
        getMemberMeta: action.payload?.getMemberMeta,
        getMemberError: null,
      }
    case GET_MEMBER_REQUEST_FAILURE:
      return {
        ...state,
        getMemberLoader: false,
        getMemberData: null,
        getMemberError: action.payload.getMemberError,
      }
    case GET_MEMBER_CACHE_DATA_CLEAR_REQUEST:
      return {
        ...state,
        getMemberData: null,
      }
    case UPDATE_MEMBER_REQUEST:
      return {
        ...state,
        updateMemberLoader: true,
      }
    case UPDATE_MEMBER_REQUEST_SUCCESS:
      return {
        ...state,
        updateMemberLoader: false,
        updateMemberData: action.payload,
        updateMemberError: null,
      }
    case UPDATE_MEMBER_REQUEST_FAILURE:
      return {
        ...state,
        updateMemberLoader: false,
        updateMemberData: null,
        updateMemberError: action.payload.updateMemberError,
      }
    case DELETE_MEMBER_REQUEST:
      return {
        ...state,
        deleteMemberLoader: true,
      }
    case DELETE_MEMBER_REQUEST_SUCCESS:
      return {
        ...state,
        deleteMemberLoader: false,
        deleteMemberError: null,
      }
    case DELETE_MEMBER_REQUEST_FAILURE:
      return {
        ...state,
        deleteMemberLoader: false,
        deleteMemberData: null,
        deleteMemberError: action.payload.deleteMemberError,
      }
    case UPDATE_MEMBER_STATUS_REQUEST:
      return {
        ...state,
        memberStatusUpdateLoader: true,
      }
    case UPDATE_MEMBER_STATUS_REQUEST_SUCCESS:
      return {
        ...state,
        memberStatusUpdateLoader: false,
        memberStatusUpdateData: action.payload,
        memberStatusUpdateError: null,
      }
    case UPDATE_MEMBER_STATUS_REQUEST_FAILURE:
      return {
        ...state,
        memberStatusUpdateLoader: false,
        memberStatusUpdateData: null,
        memberStatusUpdateError: action.payload.memberStatusUpdateError,
      }
    case VIEW_MEMBER_REQUEST:
      return {
        ...state,
        viewMemberLoader: true,
      }
    case VIEW_MEMBER_REQUEST_SUCCESS:
      return {
        ...state,
        viewMemberLoader: false,
        viewMemberData: action.payload,
        viewMemberError: null,
      }
    case VIEW_MEMBER_REQUEST_FAILURE:
      return {
        ...state,
        viewMemberLoader: false,
        viewMemberData: null,
        viewMemberError: action.payload.viewMemberError,
      }
    case VIEW_MEMBER_CACHE_DATA_CLEAR_REQUEST:
      return {
        ...state,
        viewMemberData: null,
      }
    case GET_ALL_CLINIC_REQUEST:
      return {
        ...state,
        allClinicLoader: true,
      }
    case GET_ALL_CLINIC_REQUEST_SUCCESS:
      return {
        ...state,
        allClinicLoader: false,
        allClinicData: action?.payload?.allClinicData,
        allClinicError: null,
      }
    case GET_ALL_CLINIC_REQUEST_FAILURE:
      return {
        ...state,
        allClinicLoader: false,
        allClinicData: null,
        allClinicError: action?.payload?.allClinicError,
      }
    case EDIT_ROLE_TO_MEMBER_REQUEST:
      return {
        ...state,
        editRoleToMemberLoader: true,
      }
    case EDIT_ROLE_TO_MEMBER_REQUEST_SUCCESS:
      return {
        ...state,
        editRoleToMemberLoader: false,
        editRoleToMemberData: action?.payload?.editRoleToMemberData,
        editRoleToMemberError: null,
      }
    case EDIT_ROLE_TO_MEMBER_REQUEST_FAILURE:
      return {
        ...state,
        editRoleToMemberLoader: false,
        editRoleToMemberData: null,
        editRoleToMemberError: action?.payload?.editRoleToMemberError,
      }
    case UPDATE_MEMBER_STATUS_LOCAL:
      let getMember = state.getMemberData
      getMember = getMember?.map((res: any, i: number) => {
        if (i === action?.payload?.index) {
          return {
            ...res,
            status: res?.status === 'Active' ? 'Inactive' : 'Active',
          }
        }
        return res
      })
      return {
        ...state,
        getMemberData: getMember,
      }
    default:
      return {
        ...state,
      }
  }
}

export default memberReducer
