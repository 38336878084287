import axios from 'axios'
import { all, put, takeEvery } from 'redux-saga/effects'
import API from '../../../utils/api'
import { errorToaster, successToaster } from '../../../utils/helper'
import { PUT_CLINIC_SETTINGS_REQUEST } from '../../actions/actionTypes'
import {
  putClinicSettingsRequestFailure,
  putClinicSettingsRequestSuccess,
  updateClinicSettingsValidationAction,
} from '../../actions/clinic/clinicSettingsAction'

// update clinic settings
function* putClinicSettingsRequestSaga(payload: any): any {
  try {
    const response = yield API.put(`/v1/clinic/settings/${payload?.payload?.clinic_id}`, payload?.payload)
    if (payload?.payload?.sync_schedule) {
      yield axios.patch(
        `${process.env.REACT_APP_PMS_API_URL}/settings/schedule/${payload?.payload?.clinic_id}`,
        {
          schedule: payload?.payload?.sync_schedule,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('ownerToken')}`,
          },
        }
      )
    }
    successToaster(response?.data?.meta?.message)
    yield put(putClinicSettingsRequestSuccess(response.data?.detail))
    yield put(updateClinicSettingsValidationAction({ clinic_email: null }))
  } catch (e: any) {
    errorToaster(e?.response?.data?.detail)
    yield put(putClinicSettingsRequestFailure(e?.meta?.message))
  }
}

function* putClinicSettingsWatch() {
  yield takeEvery(PUT_CLINIC_SETTINGS_REQUEST, putClinicSettingsRequestSaga)
}

export default function* clinicSettingSaga() {
  yield all([putClinicSettingsWatch()])
}
