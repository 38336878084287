import {
  CLINIC_ADMIN_SIGNUP_REQUEST,
  CLINIC_ADMIN_SIGNUP_REQUEST_FAILURE,
  CLINIC_ADMIN_SIGNUP_REQUEST_SUCCESS,
  SET_SIGN_UP_DATA_LOCAL,
} from '../actionTypes'

import {
  ClinicAdminSignUpFailurePayload,
  ClinicAdminSignUpRequest,
  ClinicAdminSignUpRequestFailure,
  ClinicAdminSignUpRequestPayload,
  ClinicAdminSignUpRequestSuccess,
  ClinicAdminSignUpRequestSuccessPayload,
  SetSignUpDataRequest,
  SetSignUpDataRequestPayload,
} from '../../schema/auth/signUpSchema'

export const clinicAdminSignUpRequest = (payload: ClinicAdminSignUpRequestPayload): ClinicAdminSignUpRequest => ({
  type: CLINIC_ADMIN_SIGNUP_REQUEST,
  payload,
})

export const clinicAdminSignUpRequestSuccess = (payload: ClinicAdminSignUpRequestSuccessPayload): ClinicAdminSignUpRequestSuccess => ({
  type: CLINIC_ADMIN_SIGNUP_REQUEST_SUCCESS,
  payload,
})

export const clinicAdminSignUpRequestFailure = (payload: ClinicAdminSignUpFailurePayload): ClinicAdminSignUpRequestFailure => ({
  type: CLINIC_ADMIN_SIGNUP_REQUEST_FAILURE,
  payload,
})

export const SetSignUpDataRequestAction = (payload: SetSignUpDataRequestPayload): SetSignUpDataRequest => ({
  type: SET_SIGN_UP_DATA_LOCAL,
  payload,
})
