import { all, call, put, takeEvery } from 'redux-saga/effects'
import API from '../../../utils/api'
import { errorToaster, successToaster } from '../../../utils/helper'
import { CLINIC_ADMIN_SIGNUP_REQUEST } from '../../actions/actionTypes'
import { clinicAdminSignUpRequestFailure, clinicAdminSignUpRequestSuccess } from '../../actions/authActions/signUpAction'

function* clinicAdminSignUpRequestSaga(payload: any): any {
  try {
    const response = yield API.post(`/v1/owner/signup`, payload.payload)
    yield call(payload.payload.callback, response.data)
//     successToaster(response?.data?.meta?.message)
    yield put(
      clinicAdminSignUpRequestSuccess({
        signUpData: response.data,
      })
    )
  } catch (e: any) {
    errorToaster(e?.meta?.message)
    yield put(
      clinicAdminSignUpRequestFailure({
        signUpError: e?.meta?.message,
      })
    )
  }
}

function* postClinicAdminSignUpSaga() {
  yield takeEvery(CLINIC_ADMIN_SIGNUP_REQUEST, clinicAdminSignUpRequestSaga)
}

export default function* clinicAdminSignUpSaga() {
  yield all([postClinicAdminSignUpSaga()])
}
