import {
  ADD_CLINIC_DATA_LOCAL,
  CHANGE_MSA_AGREEMENT,
  CHANGE_PRACTICE_INFORMATION,
  CREATE_CLINIC_REQUEST,
  CREATE_CLINIC_REQUEST_FAILURE,
  CREATE_CLINIC_REQUEST_SUCCESS,
  DELETE_CLINIC_REQUEST,
  DELETE_CLINIC_REQUEST_FAILURE,
  DELETE_CLINIC_REQUEST_SUCCESS,
  DOWNLOAD_CSV_FILE_REQUEST,
  DOWNLOAD_CSV_FILE_REQUEST_FAIL,
  DOWNLOAD_CSV_FILE_REQUEST_SUCCESS,
  EDIT_CLINIC_DATA_LOCAL,
  GET_ALL_MEMBER_REQUEST,
  GET_ALL_MEMBER_REQUEST_FAILURE,
  GET_ALL_MEMBER_REQUEST_SUCCESS,
  GET_CLINIC_CACHE_DATA_CLEAR_REQUEST,
  GET_CLINIC_REQUEST,
  GET_CLINIC_REQUEST_FAILURE,
  GET_CLINIC_REQUEST_SUCCESS,
  GET_MSA_AGREEMENT_REQUEST,
  GET_MSA_AGREEMENT_REQUEST_FAIL,
  GET_MSA_AGREEMENT_REQUEST_SUCCESS,
  GET_PAYMENT_LINK_REQUEST,
  GET_PAYMENT_LINK_REQUEST_FAILURE,
  GET_PAYMENT_LINK_REQUEST_SUCCESS,
  GET_PMS_SOFTWARE_REQUEST,
  GET_PMS_SOFTWARE_REQUEST_FAILURE,
  GET_PMS_SOFTWARE_REQUEST_SUCCESS,
  GET_PRACTICE_INFORMATION_REQUEST,
  GET_PRACTICE_INFORMATION_REQUEST_FAIL,
  GET_PRACTICE_INFORMATION_REQUEST_SUCCESS,
  GET_PROCEDURE_CODE_CACHE_DATA_CLEAR_REQUEST,
  GET_PROCEDURE_CODE_REQUEST,
  GET_PROCEDURE_CODE_REQUEST_FAILURE,
  GET_PROCEDURE_CODE_REQUEST_SUCCESS,
  GET_SELECTED_PROCEDURE_DATA,
  PUT_MSA_AGREEMENT_REQUEST,
  PUT_MSA_AGREEMENT_REQUEST_FAIL,
  PUT_MSA_AGREEMENT_REQUEST_SUCCESS,
  PUT_PRACTICE_INFORMATION_REQUEST,
  PUT_PRACTICE_INFORMATION_REQUEST_FAIL,
  PUT_PRACTICE_INFORMATION_REQUEST_SUCCESS,
  PUT_PROCEDURE_DATA_REQUEST,
  PUT_PROCEDURE_DATA_REQUEST_FAILURE,
  PUT_PROCEDURE_DATA_REQUEST_SUCCESS,
  UPDATE_CLINIC_REQUEST,
  UPDATE_CLINIC_REQUEST_FAILURE,
  UPDATE_CLINIC_REQUEST_SUCCESS,
  UPDATE_CLINIC_SPECIFIC_PROCEDURE_DATA_LOCALLY,
  UPDATE_CLINIC_SPECIFIC_PROCEDURE_DATA_VALIDATIONS,
  UPDATE_CLINIC_STATUS_LOCAL,
  UPDATE_CLINIC_STATUS_REQUEST,
  UPDATE_CLINIC_STATUS_REQUEST_FAILURE,
  UPDATE_CLINIC_STATUS_REQUEST_SUCCESS,
  UPDATE_MSA_AGREEMENT_VALIDATION,
  UPDATE_PRACTICE_INFORMATION_VALIDATION,
  UPDATE_PROCEDURE_DATA_LOCALLY,
  UPDATE_PROCEDURE_DATA_VALIDATIONS,
  UPLOAD_CSV_FILE_REQUEST,
  UPLOAD_CSV_FILE_REQUEST_FAIL,
  UPLOAD_CSV_FILE_REQUEST_SUCCESS,
  VIEW_CLINIC_CACHE_DATA_CLEAR_REQUEST,
  VIEW_CLINIC_REQUEST,
  VIEW_CLINIC_REQUEST_FAILURE,
  VIEW_CLINIC_REQUEST_SUCCESS,
} from '../actionTypes'

import {
  AddClinicDataLocalInterface,
  AddClinicDataLocalRequest,
  ChangeMsaAgreementPayload,
  ChangeMsaAgreementRequest,
  ChangePracticeInformationPayload,
  ChangePracticeInformationRequest,
  ClinicStatusUpdateFailurePayload,
  ClinicStatusUpdateRequest,
  ClinicStatusUpdateRequestFailure,
  ClinicStatusUpdateRequestPayload,
  ClinicStatusUpdateRequestSuccess,
  ClinicStatusUpdateRequestSuccessPayload,
  CreateClinicFailurePayload,
  CreateClinicRequest,
  CreateClinicRequestFailure,
  CreateClinicRequestSuccess,
  CreateClinicRequestSuccessPayload,
  DeleteClinicFailurePayload,
  DeleteClinicRequest,
  DeleteClinicRequestFailure,
  DeleteClinicRequestPayload,
  DeleteClinicRequestSuccess,
  DeleteClinicRequestSuccessPayload,
  DownloadCsvFileRequest,
  DownloadCsvFileRequestFailure,
  DownloadCsvFileRequestFailurePayload,
  DownloadCsvFileRequestSuccess,
  DownloadCsvFileRequestSuccessPayload,
  EditClinicDataLocalInterface,
  EditClinicDataLocalRequest,
  GetAllMemberRequest,
  GetAllMemberRequestFailure,
  GetAllMemberRequestFailurePayload,
  GetAllMemberRequestPayload,
  GetAllMemberRequestSuccess,
  GetAllMemberRequestSuccessPayload,
  getClinicFailurePayload,
  GetClinicRequest,
  GetClinicRequestFailure,
  getClinicRequestPayload,
  GetClinicRequestSuccess,
  getClinicRequestSuccessPayload,
  GetMsaAgreementRequest,
  GetMsaAgreementRequestFailure,
  GetMsaAgreementRequestFailurePayload,
  GetMsaAgreementRequestPayload,
  GetMsaAgreementRequestSuccess,
  GetMsaAgreementRequestSuccessPayload,
  GetPaymentLinkRequest,
  GetPaymentLinkRequestFailure,
  GetPaymentLinkRequestFailurePayload,
  GetPaymentLinkRequestPayload,
  GetPaymentLinkRequestSuccess,
  GetPaymentLinkRequestSuccessPayload,
  GetPMSSoftwareRequest,
  GetPMSSoftwareRequestFailure,
  GetPMSSoftwareRequestFailurePayload,
  GetPMSSoftwareRequestSuccess,
  GetPMSSoftwareRequestSuccessPayload,
  GetPracticeInformationRequest,
  GetPracticeInformationRequestFailure,
  GetPracticeInformationRequestFailurePayload,
  GetPracticeInformationRequestPayload,
  GetPracticeInformationRequestSuccess,
  GetPracticeInformationRequestSuccessPayload,
  GetProcedureCodeRequest,
  GetProcedureCodeRequestFailure,
  GetProcedureCodeRequestFailurePayload,
  GetProcedureCodeRequestPayload,
  GetProcedureCodeRequestSuccess,
  GetProcedureCodeRequestSuccessPayload,
  GetSelectedProcedureData,
  GetSelectedProcedureDataPayload,
  PutMsaAgreementRequest,
  PutMsaAgreementRequestFailure,
  PutMsaAgreementRequestFailurePayload,
  PutMsaAgreementRequestPayload,
  PutMsaAgreementRequestSuccess,
  PutMsaAgreementRequestSuccessPayload,
  PutPracticeInformationRequest,
  PutPracticeInformationRequestFailure,
  PutPracticeInformationRequestFailurePayload,
  PutPracticeInformationRequestPayload,
  PutPracticeInformationRequestSuccess,
  PutPracticeInformationRequestSuccessPayload,
  PutProcedureDataRequest,
  PutProcedureDataRequestFailure,
  PutProcedureDataRequestFailurePayload,
  PutProcedureDataRequestPayload,
  PutProcedureDataRequestSuccess,
  PutProcedureDataRequestSuccessPayload,
  UpdateClinicFailurePayload,
  UpdateClinicRequest,
  UpdateClinicRequestFailure,
  UpdateClinicRequestPayload,
  UpdateClinicRequestSuccess,
  UpdateClinicRequestSuccessPayload,
  UpdateClinicSpecificProcedureDataLocally,
  UpdateClinicSpecificProcedureDataLocallyPayload,
  UpdateClinicSpecificProcedureDataValidations,
  UpdateClinicSpecificProcedureDataValidationsPayload,
  UpdateClinicStatusLocal,
  updateClinicStatusLocalPayload,
  UpdateMsaAgreementValidationPayload,
  UpdateMsaAgreementValidationRequest,
  UpdatePracticeInformationValidationPayload,
  UpdatePracticeInformationValidationRequest,
  UpdateProcedureDataLocally,
  UpdateProcedureDataLocallyPayload,
  UpdateProcedureDataValidations,
  UpdateProcedureDataValidationsPayload,
  UploadCsvFileRequest,
  UploadCsvFileRequestFailure,
  UploadCsvFileRequestFailurePayload,
  UploadCsvFileRequestSuccess,
  UploadCsvFileRequestSuccessPayload,
  ViewClinicFailurePayload,
  ViewClinicRequest,
  ViewClinicRequestFailure,
  ViewClinicRequestPayload,
  ViewClinicRequestSuccess,
  ViewClinicRequestSuccessPayload,
} from '../../schema/clinic/clinicSchema'

// create clinic
export const createClinicRequest = (payload: any): CreateClinicRequest => ({
  type: CREATE_CLINIC_REQUEST,
  payload,
})
export const createClinicRequestSuccess = (payload: CreateClinicRequestSuccessPayload): CreateClinicRequestSuccess => ({
  type: CREATE_CLINIC_REQUEST_SUCCESS,
  payload,
})
export const createClinicRequestFailure = (payload: CreateClinicFailurePayload): CreateClinicRequestFailure => ({
  type: CREATE_CLINIC_REQUEST_FAILURE,
  payload,
})

// get clinic

export const getClinicRequest = (payload: getClinicRequestPayload): GetClinicRequest => ({
  type: GET_CLINIC_REQUEST,
  payload,
})
export const getClinicRequestSuccess = (payload: getClinicRequestSuccessPayload): GetClinicRequestSuccess => ({
  type: GET_CLINIC_REQUEST_SUCCESS,
  payload,
})
export const getClinicRequestFailure = (payload: getClinicFailurePayload): GetClinicRequestFailure => ({
  type: GET_CLINIC_REQUEST_FAILURE,
  payload,
})
export const getClinicCacheDataClearRequest = (): any => ({
  type: GET_CLINIC_CACHE_DATA_CLEAR_REQUEST,
})

// view clinic
export const viewClinicRequest = (payload: ViewClinicRequestPayload): ViewClinicRequest => ({
  type: VIEW_CLINIC_REQUEST,
  payload,
})
export const viewClinicRequestSuccess = (payload: ViewClinicRequestSuccessPayload): ViewClinicRequestSuccess => ({
  type: VIEW_CLINIC_REQUEST_SUCCESS,
  payload,
})
export const viewClinicRequestFailure = (payload: ViewClinicFailurePayload): ViewClinicRequestFailure => ({
  type: VIEW_CLINIC_REQUEST_FAILURE,
  payload,
})
export const viewClinicCacheDataClearRequest = (): any => ({
  type: VIEW_CLINIC_CACHE_DATA_CLEAR_REQUEST,
})

// update clinic
export const updateClinicRequest = (payload: UpdateClinicRequestPayload): UpdateClinicRequest => ({
  type: UPDATE_CLINIC_REQUEST,
  payload,
})
export const updateClinicRequestSuccess = (payload: UpdateClinicRequestSuccessPayload): UpdateClinicRequestSuccess => ({
  type: UPDATE_CLINIC_REQUEST_SUCCESS,
  payload,
})
export const updateClinicRequestFailure = (payload: UpdateClinicFailurePayload): UpdateClinicRequestFailure => ({
  type: UPDATE_CLINIC_REQUEST_FAILURE,
  payload,
})

// delete clinic
export const deleteClinicRequest = (payload: DeleteClinicRequestPayload): DeleteClinicRequest => ({
  type: DELETE_CLINIC_REQUEST,
  payload,
})
export const deleteClinicRequestSuccess = (payload: DeleteClinicRequestSuccessPayload): DeleteClinicRequestSuccess => ({
  type: DELETE_CLINIC_REQUEST_SUCCESS,
  payload,
})
export const deleteClinicRequestFailure = (payload: DeleteClinicFailurePayload): DeleteClinicRequestFailure => ({
  type: DELETE_CLINIC_REQUEST_FAILURE,
  payload,
})

// update clinic status
export const clinicUpdateStatusRequest = (payload: ClinicStatusUpdateRequestPayload): ClinicStatusUpdateRequest => ({
  type: UPDATE_CLINIC_STATUS_REQUEST,
  payload,
})
export const clinicUpdateStatusRequestSuccess = (payload: ClinicStatusUpdateRequestSuccessPayload): ClinicStatusUpdateRequestSuccess => ({
  type: UPDATE_CLINIC_STATUS_REQUEST_SUCCESS,
  payload,
})
export const clinicUpdateStatusRequestFailure = (payload: ClinicStatusUpdateFailurePayload): ClinicStatusUpdateRequestFailure => ({
  type: UPDATE_CLINIC_STATUS_REQUEST_FAILURE,
  payload,
})
export const updateClinicStatusLocalRequest = (payload: updateClinicStatusLocalPayload): UpdateClinicStatusLocal => ({
  type: UPDATE_CLINIC_STATUS_LOCAL,
  payload,
})
// get PMS software
export const getPMSSoftwareRequest = (): GetPMSSoftwareRequest => ({
  type: GET_PMS_SOFTWARE_REQUEST,
})
export const getPMSSoftwareRequestSuccess = (payload: GetPMSSoftwareRequestSuccessPayload): GetPMSSoftwareRequestSuccess => ({
  type: GET_PMS_SOFTWARE_REQUEST_SUCCESS,
  payload,
})
export const getPMSSoftwareRequestFailure = (payload: GetPMSSoftwareRequestFailurePayload): GetPMSSoftwareRequestFailure => ({
  type: GET_PMS_SOFTWARE_REQUEST_FAILURE,
  payload,
})

// get all member
export const getAllMemberRequest = (payload: GetAllMemberRequestPayload): GetAllMemberRequest => ({
  type: GET_ALL_MEMBER_REQUEST,
  payload,
})
export const getAllMemberRequestSuccess = (payload: GetAllMemberRequestSuccessPayload): GetAllMemberRequestSuccess => ({
  type: GET_ALL_MEMBER_REQUEST_SUCCESS,
  payload,
})
export const getAllMemberRequestFailure = (payload: GetAllMemberRequestFailurePayload): GetAllMemberRequestFailure => ({
  type: GET_ALL_MEMBER_REQUEST_FAILURE,
  payload,
})

// get procedure code via clinic
export const getProcedureCodeRequest = (payload: GetProcedureCodeRequestPayload): GetProcedureCodeRequest => ({
  type: GET_PROCEDURE_CODE_REQUEST,
  payload,
})
export const getProcedureCodeRequestSuccess = (payload: GetProcedureCodeRequestSuccessPayload): GetProcedureCodeRequestSuccess => ({
  type: GET_PROCEDURE_CODE_REQUEST_SUCCESS,
  payload,
})
export const getProcedureCodeRequestFailure = (payload: GetProcedureCodeRequestFailurePayload): GetProcedureCodeRequestFailure => ({
  type: GET_PROCEDURE_CODE_REQUEST_FAILURE,
  payload,
})
export const getProcedureCodeCacheDataClearRequest = (): any => ({
  type: GET_PROCEDURE_CODE_CACHE_DATA_CLEAR_REQUEST,
})

// update procedure data locally
export const getSelectedProcedureData = (payload: GetSelectedProcedureDataPayload): GetSelectedProcedureData => ({
  type: GET_SELECTED_PROCEDURE_DATA,
  payload,
})

// update procedure data locally
export const updateProcedureDataLocally = (payload: UpdateProcedureDataLocallyPayload): UpdateProcedureDataLocally => ({
  type: UPDATE_PROCEDURE_DATA_LOCALLY,
  payload,
})

// update procedure data validation
export const updateProcedureDataValidationsAction = (payload: UpdateProcedureDataValidationsPayload): UpdateProcedureDataValidations => ({
  type: UPDATE_PROCEDURE_DATA_VALIDATIONS,
  payload,
})
export const updateClinicSpecificProcedureDataLocally = (
  payload: UpdateClinicSpecificProcedureDataLocallyPayload
): UpdateClinicSpecificProcedureDataLocally => ({
  type: UPDATE_CLINIC_SPECIFIC_PROCEDURE_DATA_LOCALLY,
  payload,
})

// update procedure data validation
export const updateClinicSpecificProcedureDataValidationsAction = (
  payload: UpdateClinicSpecificProcedureDataValidationsPayload
): UpdateClinicSpecificProcedureDataValidations => ({
  type: UPDATE_CLINIC_SPECIFIC_PROCEDURE_DATA_VALIDATIONS,
  payload,
})

// update procedure data
export const putProcedureDataRequest = (payload: PutProcedureDataRequestPayload): PutProcedureDataRequest => ({
  type: PUT_PROCEDURE_DATA_REQUEST,
  payload,
})
export const putProcedureDataRequestSuccess = (payload: PutProcedureDataRequestSuccessPayload): PutProcedureDataRequestSuccess => ({
  type: PUT_PROCEDURE_DATA_REQUEST_SUCCESS,
  payload,
})
export const putProcedureDataRequestFailure = (payload: PutProcedureDataRequestFailurePayload): PutProcedureDataRequestFailure => ({
  type: PUT_PROCEDURE_DATA_REQUEST_FAILURE,
  payload,
})

// download csv file
export const downloadCsvFileRequestAction = (payload?: any): DownloadCsvFileRequest => ({
  type: DOWNLOAD_CSV_FILE_REQUEST,
  payload,
})
export const downloadCsvFileRequestSuccessAction = (payload: DownloadCsvFileRequestSuccessPayload): DownloadCsvFileRequestSuccess => ({
  type: DOWNLOAD_CSV_FILE_REQUEST_SUCCESS,
  payload,
})
export const downloadCsvFileRequestFailureAction = (payload: DownloadCsvFileRequestFailurePayload): DownloadCsvFileRequestFailure => ({
  type: DOWNLOAD_CSV_FILE_REQUEST_FAIL,
  payload,
})

// upload csv file
export const uploadCsvFileRequestAction = (payload: any): UploadCsvFileRequest => ({
  type: UPLOAD_CSV_FILE_REQUEST,
  payload,
})
export const uploadCsvFileRequestSuccessAction = (payload: UploadCsvFileRequestSuccessPayload): UploadCsvFileRequestSuccess => ({
  type: UPLOAD_CSV_FILE_REQUEST_SUCCESS,
  payload,
})
export const uploadCsvFileRequestFailureAction = (payload: UploadCsvFileRequestFailurePayload): UploadCsvFileRequestFailure => ({
  type: UPLOAD_CSV_FILE_REQUEST_FAIL,
  payload,
})

// practice information
export const getPracticeInformationRequest = (payload: GetPracticeInformationRequestPayload): GetPracticeInformationRequest => ({
  type: GET_PRACTICE_INFORMATION_REQUEST,
  payload,
})
export const getPracticeInformationRequestSuccess = (
  payload: GetPracticeInformationRequestSuccessPayload
): GetPracticeInformationRequestSuccess => ({
  type: GET_PRACTICE_INFORMATION_REQUEST_SUCCESS,
  payload,
})
export const getPracticeInformationRequestFailure = (
  payload: GetPracticeInformationRequestFailurePayload
): GetPracticeInformationRequestFailure => ({
  type: GET_PRACTICE_INFORMATION_REQUEST_FAIL,
  payload,
})

export const putPracticeInformationRequest = (payload: PutPracticeInformationRequestPayload): PutPracticeInformationRequest => ({
  type: PUT_PRACTICE_INFORMATION_REQUEST,
  payload,
})
export const putPracticeInformationRequestSuccess = (
  payload: PutPracticeInformationRequestSuccessPayload
): PutPracticeInformationRequestSuccess => ({
  type: PUT_PRACTICE_INFORMATION_REQUEST_SUCCESS,
  payload,
})
export const putPracticeInformationRequestFailure = (
  payload: PutPracticeInformationRequestFailurePayload
): PutPracticeInformationRequestFailure => ({
  type: PUT_PRACTICE_INFORMATION_REQUEST_FAIL,
  payload,
})

export const changePracticeInformationRequest = (payload: ChangePracticeInformationPayload): ChangePracticeInformationRequest => ({
  type: CHANGE_PRACTICE_INFORMATION,
  payload,
})
export const updatePracticeInformationValidationRequest = (
  payload: UpdatePracticeInformationValidationPayload
): UpdatePracticeInformationValidationRequest => ({
  type: UPDATE_PRACTICE_INFORMATION_VALIDATION,
  payload,
})

// MSA Agreement
export const putMsaAgreementRequest = (payload: PutMsaAgreementRequestPayload): PutMsaAgreementRequest => ({
  type: PUT_MSA_AGREEMENT_REQUEST,
  payload,
})
export const putMsaAgreementRequestSuccess = (payload: PutMsaAgreementRequestSuccessPayload): PutMsaAgreementRequestSuccess => ({
  type: PUT_MSA_AGREEMENT_REQUEST_SUCCESS,
  payload,
})
export const putMsaAgreementRequestFailure = (payload: PutMsaAgreementRequestFailurePayload): PutMsaAgreementRequestFailure => ({
  type: PUT_MSA_AGREEMENT_REQUEST_FAIL,
  payload,
})

export const changeMsaAgreementRequest = (payload: ChangeMsaAgreementPayload): ChangeMsaAgreementRequest => ({
  type: CHANGE_MSA_AGREEMENT,
  payload,
})
export const updateMsaAgreementValidationRequest = (payload: UpdateMsaAgreementValidationPayload): UpdateMsaAgreementValidationRequest => ({
  type: UPDATE_MSA_AGREEMENT_VALIDATION,
  payload,
})

export const getMsaAgreementRequest = (payload: GetMsaAgreementRequestPayload): GetMsaAgreementRequest => ({
  type: GET_MSA_AGREEMENT_REQUEST,
  payload,
})
export const getMsaAgreementRequestSuccess = (payload: GetMsaAgreementRequestSuccessPayload): GetMsaAgreementRequestSuccess => ({
  type: GET_MSA_AGREEMENT_REQUEST_SUCCESS,
  payload,
})
export const getMsaAgreementRequestFailure = (payload: GetMsaAgreementRequestFailurePayload): GetMsaAgreementRequestFailure => ({
  type: GET_MSA_AGREEMENT_REQUEST_FAIL,
  payload,
})

export const addClinicDataLocalRequestAction = (payload: AddClinicDataLocalInterface): AddClinicDataLocalRequest => ({
  type: ADD_CLINIC_DATA_LOCAL,
  payload,
})

export const editClinicDataLocalRequestAction = (payload: EditClinicDataLocalInterface): EditClinicDataLocalRequest => ({
  type: EDIT_CLINIC_DATA_LOCAL,
  payload,
})

export const getPaymentLinkRequest = (payload: GetPaymentLinkRequestPayload): GetPaymentLinkRequest => ({
  type: GET_PAYMENT_LINK_REQUEST,
  payload,
})
export const getPaymentLinkRequestSuccess = (payload: GetPaymentLinkRequestSuccessPayload): GetPaymentLinkRequestSuccess => ({
  type: GET_PAYMENT_LINK_REQUEST_SUCCESS,
  payload,
})
export const getPaymentLinkRequestFailure = (payload: GetPaymentLinkRequestFailurePayload): GetPaymentLinkRequestFailure => ({
  type: GET_PAYMENT_LINK_REQUEST_FAILURE,
  payload,
})
